
body {
    font-family: 'Nunito', sans-serif;
}
.info-paragraph {
    color:rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    font-size: 20px;
    font-weight: 600;
    margin-left: 60px;
    margin-bottom: 20px; /* Задаем отступ снизу для каждого абзаца */
}
.delivery{
    color:rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    margin-bottom: 40px;
}