body {
    font-family: 'Nunito', sans-serif;
}

.headerText{
    font-size:40px;
    text-align: center;
    margin-top:10px;
    font-weight: 600;
}
#section1 h2{
    margin-top: 20px;
    font-weight: 400;
    text-align: center;
}
#section1{
    width:1200px;
    
    text-align: left;
}

#section1 p{
    align-items: center;
}
@media only screen and (max-width: 912px) {
    .headerText{
      
        text-align: center;
        margin-top:50px;
        font-weight: 600;
        font-size: 30px ;
    }
    #section1 p{
        margin-top: 15px;
    }
    #section1{
        
        font-size: 15px;
        width:350px;
        margin-left: 20px;
    }
    #section1 div{
        display: flex;
        justify-content: center;
    }
}