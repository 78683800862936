
body {
    font-family: 'Nunito', sans-serif;
}
/* Изменяем цвет кнопки открытия меню в мобильной версии */
.navbar-toggler {
    background-color: #ff9d00 !important; /* Устанавливаем серый цвет для кнопки */
    border-color: #ff9d00 !important; /* Устанавливаем серый цвет для границы кнопки */
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2869,73,77, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
    /* Изменяем цвет иконки внутри кнопки на серый */
}


.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ff9d00 !important;
    --bs-btn-border-color: #ff9d00 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ff9d00 !important;
    --bs-btn-hover-border-color:#ff9d00 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff9d00 !important;
    --bs-btn-active-border-color: #ff9d00 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ff9d00 !important;;
    --bs-btn-disabled-border-color: #ff9d00 !important;
}



/* Стили для Navbar */
.navbar-dark {
    background-color: #000000 !important; 
}

.navbar-dark .nav-link {
    color: white !important; /* Цвет текста ссылок в Navbar */
    font-size: 17px;
    text-decoration: none; /* Убираем подчеркивание ссылок */
    padding: 0.5rem 1rem; /* Добавляем отступы для ссылок */
    display: inline-block; /* Обеспечиваем, что ссылки ведут себя как инлайн-блоки */
}

/* Убираем подчеркивание при наведении */
.navbar-dark .nav-link:hover {
    color: #ffc107 !important; /* Цвет текста при наведении */
    text-decoration: underline; /* Подчеркивание при наведении */
}

/* Стили для кнопки корзины */
.cart-button {
    color: white !important; /* Цвет текста корзины */
    border: none; /* Убираем границу */
    background: transparent; /* Убираем фон */
    padding: 0.5rem 1rem; /* Добавляем отступы для кнопки корзины */
    display: inline-block; /* Обеспечиваем, что кнопка корзины ведет себя как инлайн-блок */
    text-decoration: none; /* Убираем подчеркивание */
}

/* Убираем фон и стили при наведении и активном состоянии */
.cart-button:hover,
.cart-button:focus,
.cart-button:active {
    background: transparent; /* Убираем фон при наведении, фокусе и нажатии */
    color: #ffc107 !important; /* Цвет текста при наведении */
    text-decoration: underline; /* Подчеркивание при наведении */
    outline: none; /* Убираем обводку при фокусе */
}

/* Стили для выпадающего списка корзины */
.dropdown-menu {
    background-color: #343a40; /* Темный фон для выпадающего списка */
    border: none; /* Убираем границу */
    box-shadow: none; /* Убираем тень */
}

/* Стили для элементов выпадающего списка корзины */
.dropdown-item {
    background-color: transparent; /* Убираем фон у элементов */
    color: white; /* Цвет текста */
    text-decoration: none; /* Убираем подчеркивание у элементов */
}

/* Убираем фон и стили для элементов выпадающего списка при наведении */
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
    background-color: #495057; /* Темный фон при наведении */
    color: white; /* Цвет текста при наведении */
    text-decoration: none; /* Убираем подчеркивание при наведении */
    outline: none; /* Убираем обводку при фокусе */
}

/* Устанавливаем фон для Navbar при использовании фиксированной позиции */
.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

/* Устанавливаем размер логотипа по умолчанию и на разных экранах */
.logo {
    width: 100%; /* Размер по умолчанию */
    max-width: 300px; /* Максимальная ширина */
    height: auto; /* Автоматическая высота */
}

/* .orders{
    margin-right: 150px;
    color: white;
} */    
/* Стили для Badge */

@media (max-width: 1200px) {
    .logo {
        max-width: 130px; /* Максимальная ширина для экранов меньше 1200px */
    }
}

@media (max-width: 992px) {
    .logo {
        max-width: 270px; /* Максимальная ширина для экранов меньше 992px */
    }
}

@media (max-width: 992px) {
    .logo {
        max-width: 250px; /* Максимальная ширина для экранов меньше 768px */
    }
    .CartDropdown {
        display: flex;
        align-items: center; /* Выравнивание по вертикали */
    }
    .navbar-nav {
        flex-direction: column;
        text-align: center;
    }

    .nav-link {
        
        color: black !important; 
        font-size: 1rem !important;
        margin: 0 1rem !important; /* Отступы слева и справа */
        font-weight: 300 !important;
        text-decoration: none !important;
    }
    
    .nav-linkBasket {
        color: black !important;
        font-size: 28px !important;
        margin: 0 0 0 2rem !important; /* Отступы, как у других элементов */
        font-weight: 300 !important;
        text-decoration: none !important;
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Выравнивание по левому краю */
        padding: 8px 0 8px 0 !important;/* Добавьте отступы, если нужно */
    }
    
    /* Стили для Badge */
    .badge {
        margin-left: 0.5rem; /* Отступ от текста "Корзина" */
        vertical-align: middle; /* Выравнивание по вертикали */
    }
    .Nav {
        display: flex;
        align-items: center; /* Выравнивание по вертикали */
    }
    .nav-link:hover {
        color: grey !important; 
    }
    .CartDropdown {
        margin: 0 !important; /* Удаление лишних отступов */
        padding: 0 !important;
    }
}

@media (max-width: 830px) {
    .container {
        display: block;
        flex-direction: initial;
        align-items: initial;
    }
}
@media (max-width: 821px) {
    
    .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
        display: flex;
        align-items: center;
        flex-direction: row ;
    }
}
