/* css/ScrollToTopArrow.css
.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 100px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.scroll-to-top.show {
    display: flex;
}

.scroll-to-top:hover {
    background-color: #ffffff;
} */
body {
    font-family: 'Nunito', sans-serif;
}




.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 20px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
}

.scroll-to-top.show {
    display: flex;
    opacity: 1;
    visibility: visible;
}

.scroll-to-top:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .scroll-to-top {
        
        right: 45px;
        width: 40px;
        height: 40px;
        padding: 5px;
    }
}
