/* 
body {
    font-family: 'Nunito', sans-serif;
}






.imgModal{
    max-width: 450px;
    height: auto;
}
@media (max-width: 768px) {
    .imgModal{
        width: 100%;
        height: auto;
    }
} */



/* Основные стили для модального окна */
/* .imgModal {
    max-width: 450px;
    height: auto;
} */
.imgModal {
    width: 450px; 
    height: 350px;
    object-fit: cover; /* Обрезает изображение, чтобы оно заполняло контейнер */
    border-radius: 5px 5px 0px 0px;
}

/* Убедимся, что заглушка не выходит за пределы контейнера */


/* Стили для мобильных устройств */
@media (max-width: 576px) {
    .imgModal{
        width: 100%;
        height: auto;
    }
    .modal-dialog {
        max-width: 90%; /* Уменьшает ширину модального окна */
        margin: 10px auto; /* Центрирует модальное окно и добавляет отступы */
    }

    .modal-content {
        border-radius: 5px;
    }
    
    /* .product-name {
        font-size: 24px;
    } */

    .product-info-description {
        font-size: 14px; /* Уменьшает размер текста */
        word-break: break-word; /* Предотвращает выход текста за пределы блока */
    }

    .modal-footer {
        font-size: 14px; /* Уменьшает размер текста в футере */
    }
}
