.example-carousel-image-container {
    max-width: 100%;
    max-height: 700px;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.example-carousel-image {

    max-width: auto;
    height: auto; /* Или задайте конкретную высоту, если необходимо */
}