body {
    font-family: 'Nunito', sans-serif;
}


.Container {
   
    display: flex;
    
    justify-content: center; /* Центрирование по вертикали */
    align-items: center; /* Центрирование по горизонтали */
}

.custom-button {
    width: 100%;
    max-width: 300px; /* Ограничиваем максимальную ширину */
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.5rem;
}


.product-edit-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product-info {
    margin-bottom: 20px;
}

.product-info div {
    margin-bottom: 5px;
}
