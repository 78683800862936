



/* Основные стили для карточек продуктов */


body {
    font-family: 'Nunito', sans-serif;
}
.card {
    margin: 10px; /* Уменьшите значение для уменьшения расстояния */
}

.coll {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px; /* Добавлено для регулирования вертикальных отступов */
}

.imgCard {
    max-width: 250px;
    width: 100%;
    height: auto;
}

.text-center {
    padding: 10px;
}

.product-name {
    flex: 1;
}

.product-price {
    font-weight: bold;
}

.add-to-cart-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* Иконка добавления в корзину */
.icon {
    transition: transform 0.3s ease-in-out;
}

.icon-rotate {
    transform: rotate(360deg);
}

/* .custom-icon-size {
    font-size: 24px; 
} */

/* Медиа-запрос для мобильных устройств */
@media (max-width: 576px) {
    .card {
        margin: 5px; /* Уменьшите расстояние между карточками на мобильных устройствах */
    }

    .coll {
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 5px;
    }

    .text-center {
        padding: 5px; /* Уменьшите отступы на мобильных устройствах */
    }

    .product-price {
        font-size: 14px; /* Уменьшите размер шрифта на мобильных устройствах */
    }

    .add-to-cart-icon {
        font-size: 16px; /* Уменьшите размер иконки на мобильных устройствах */
    }

    /* Обеспечить, чтобы элементы не выходили за пределы блока */
    .d-flex {
        flex-wrap: wrap; /* Позволяет элементам обертываться на мобильных устройствах */
    }

    

    .imgCard {
        width: 100%; /* Убедитесь, что изображение не выходит за пределы карточки */
    }
    
    .product-name, .product-price {
        white-space: wrap; /* Предотвратить перенос текста */
        overflow: hidden; /* Скрыть текст, выходящий за пределы */
        text-overflow: ellipsis; /* Добавить многоточие в конце текста, если он слишком длинный */
    }
}
