

body {
    font-family: 'Nunito', sans-serif;
}

.kolvo{
    display: flex;
    flex-direction: column
}


.alert{
    background-color: #0000002a;
    margin-top: 26px;
    width: 100%;
    max-height: 100%;
}



.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative; /* Добавлено */
}

.cart-item-price {
    
    flex: 1;
    /* margin-right: 60px;  */
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
    position: relative; /* Относительное позиционирование */
    z-index: 1; /* Установим на один уровень выше */
}

.button-remove {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2; /* Убедитесь, что кнопка удаления находится выше цены */
}










/* Основные стили для карты */
.map-container {
    width: 100%; /* ширина на весь экран */
    max-width: 100vw; /* максимальная ширина на весь экран */
    position: relative;
    margin-top: 150px; /* отступ сверху */ 
    padding: 0;
    margin-bottom: 200px;
    overflow: hidden; /* предотвращает выход карты за пределы контейнера */
}

.map {
    width: 100%; /* ширина карты */
    height: 500px; /* высота карты */
}

/* Медиазапросы для мобильных устройств */
@media only screen and (max-width: 912px) {
    .map {
        height: 400px; /* уменьшение высоты карты на мобильных устройствах */
    }
}

@media only screen and (max-width: 820px) {
    .map {
        height: 300px; /* еще большее уменьшение высоты карты на маленьких устройствах */
    }
}

/* Остальные стили остаются без изменений */

.agreement-text2 {
    color: black; /* Цвет по умолчанию */
}

.agreement-text2.error {
    color: red;
}
/* Стили для ошибок */
.error {
    border: 2px solid red;
    background-color: #fdd;
}
/* Basket.css */

/* Стили для ошибок чекбоксов */
.checkbox-container .error {
    border: 2px solid red;
    background-color: #fdd;
}
.con {
    max-width: 365px;
    margin-top: 0;
}

/* Hide number input spin buttons for all browsers */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide number input spin buttons for Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    /* align-items: flex-start; */
}

.totalPrice {
    font-size: 25px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    gap: 12rem;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: right;
}



.cart-item input {

    outline: solid #c9c9c9;
    outline-width: thin;
    width: 45px !important; /* уменьшение ширины поля ввода */
    height: 40px !important; /* уменьшение высоты поля ввода */
    font-size: 17px; /* уменьшение размера шрифта */
    text-align: center;
    border: none;
    border-radius: 2px !important;
}

.cart-item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.price {
    padding-left: 10px !important;
}

.cart-item-name {
    flex-wrap: wrap;
    text-align: left;
    /* min-width: 150px; */
    margin-right: 10px;
    max-width: 150px;
    min-width:150px;
}

button.p-0 {
    margin-left: 10px;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkbox-label1 {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row-reverse;
}

.checkbox-label2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.checkbox1, .checkbox2 {
    width: 21px;
    height: 21px;
    margin-right: 10px;
}

.agreement-text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.agreement-text2 {
    font-size: 14px;
    line-height: 1.5;
}

.policy-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.policy-checkbox input {
    width: 21px;
    height: 21px;
    margin-right: 10px;
}

.card {
    max-width: 450px;
}

.cart-item button {
    width: 25px; /* уменьшение ширины кнопок */
    height: 25px; /* уменьшение высоты кнопок */
    font-size: 12px; /* уменьшение размера шрифта */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.minus, .plus {
   
    color: #464545;
    outline: solid #c9c9c9;
    outline-width: thin;
}
.container_cart {
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 100px;
}
.textDatauser{
    font-size: 25px;
}
.conn{
    /* d-flex justify-content-between p-3 */
    display: flex ;
    justify-content:space-between;
    padding:30px;
}
.pozhelaniya{
    height: 64px;
    width: 342px;
    background-color: #ECECEC; 
}
.delivery-error{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.dop{
    display: flex;
}
.z00r{
    margin-left:215px;
    
}
input[type="text"], input[type="number"], textarea {
    background-color: #ECECEC; /* стандартный цвет фона */
}

/* Стили для полей ввода с ошибками */
input[type="text"].error, input[type="number"].error, textarea.error {
    border: 2px solid red; /* красная граница для полей с ошибками */
    background-color: #FFD1D1; /* светло-красный фон для полей с ошибками */
}
.datauser.error {
    border: 2px solid red;
    background-color: #fdd;
}

.pozhelaniya {
    height: 64px;
    width: 342px;
    background-color: #ECECEC;
}

.agreement-text2 {
    color: black; /* Цвет по умолчанию */
}

.agreement-text2.error {
    color: red;
}
/* Стили для ошибок */
.error {
    border: 2px solid red;
    background-color: #fdd;
}

/* Стили для ошибок чекбоксов */
.checkbox-container .error {
    border: 2px solid red;
    background-color: #fdd;
}



@media only screen and (max-width: 912px) {
    .cart-item input {
        width: 40px; /* Убедитесь, что размер ввода подходит для мобильных устройств */
        height: 40px;
        font-size: 16px; /* Убедитесь, что шрифт и размеры читаемы */
    }

    .cart-item button {
        width: 35px;
        height: 35px;
    }
    .z00r{
        margin-left:139px;
        
    }
        .pozhelaniya {
            height: 64px;
            width: 226px;
            background-color: #ECECEC; 
    }
    .conn{
        display: flex ;
        justify-content:center;
        padding:0px;
    }
    .cart-item-price {
        font-size: 15px;
        flex:1 1;
        margin-left: 0;
    }
    
    .cart-item-name {
        margin-right: -40px;
        font-size:15px;
    }
    .con{
        width:250px;
    }
    .card {
        width:80%;
    }
    .textDatauser{
        font-size: 20px;
    }
}

@media only screen and (max-width: 820px) {
    .cart-item input {
        width: 30px !important; 
        height: 40px ;
        font-size: 16px; /* Убедитесь, что шрифт и размеры читаемы */
    }

    .cart-item button {
        width: 35px;
        height: 35px;
    }
    .div {
        font-size: calc(24px + (16 + 16 * 0.7) * ((100vw - 320px) / 1280));
    }
    
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container_cart {
       display: flex;
       justify-content: flex-end;
       align-items: center
       
    }

    .card {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .cart-item {
        width: 100%;
        flex: 2;
    }

    .cart-item-name {
        font-size:15px;
        flex: 1;
        margin-top: 10px;
        margin-right: -80px;
        flex-wrap: wrap;
        text-align: start;
    }

    .cart-item-price {
        font-size: 15px;
        margin-right: -20px;
        margin-left: 10px;
        
    }


    .cart-item button {
        display: none;
        /* flex: 1;
        margin-top: 10px;
        margin-right: 0px;
        display: flex;
        justify-content: center; */

    }

    .quantity-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .quantity-controls button,
    .quantity-controls input {
        width: 30px;
        height: 30px;
        font-size: 16px;
        padding: 5px;
    }

    .checkbox-container {
        flex-direction: column;
    }

    .checkbox-label {
        margin-bottom: 10px;
    }

    .price {
        padding-left: 10px !important;
    }

    .minus, .plus, .ss {
        width: 20px;
        height: auto;
    }
}
