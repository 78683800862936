/* Ensure the html and body occupy full height
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}


body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}


.footer {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    color: white;
    padding: 20px 0;
    font-weight: 200;
    width: 100%;
    margin-top: auto;  
    text-align: center;
}

.footer .text-uppercase {
    text-transform: uppercase;
    color: white;
}

.footer .text-body {
    color: white;
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    color: white;
}

.text-center {
    text-align: center;
}

.text-lg-start {
    text-align: left;
}

.p-4 {
    padding: 1.5rem;
}

.p-3 {
    padding: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-md-0 {
    margin-bottom: 0;
}

.footer-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.num {
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}

.Vkontakte {
    width: 30px;
    height: 30px;
}

.social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-copyright {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    color: white;
}

.row.align-items-start {
    align-items: flex-start;
} */







/* Ensure the html and body occupy full height */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

/* Ensure the root element uses flex layout */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Ensure the root element takes available vertical space */
#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Footer styling */
.footer {
    background-color: #000000 !important; /* Черный фон для Footer */
    color: white;
    padding: 20px 0;
    font-weight: 200;
    width: 100%;
    margin-top: auto;  /* Pushes the footer to the bottom */
    text-align: center;
}

.footer .text-uppercase {
    text-transform: uppercase;
    color: white;
}

.footer .text-body {
    color: white;
}

.bg-body-tertiary {
    background-color: #000000 !important; /* Черный фон */
    color: white;
}

.text-center {
    text-align: center;
}

.text-lg-start {
    text-align: left;
}

.p-4 {
    padding: 1.5rem;
}

.p-3 {
    padding: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-md-0 {
    margin-bottom: 0;
}

.footer-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.num {
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}

.Vkontakte {
    width: 30px;
    height: 30px;
}

.social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-copyright {
    font-weight: 500;
    background-color: #000000 !important; /* Черный фон */
    color: white;
}

/* Align columns vertically to the top */
.row.align-items-start {
    align-items: flex-start;
}
