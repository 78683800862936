body {
    font-family: 'Nunito', sans-serif;
}






.type-bar-container {
    margin-bottom: 15px; /* Отступ снизу */
    border-radius: 8px; /* Скругленные углы */
    overflow: hidden; /* Чтобы содержимое не выходило за границы скругленных углов */
}

.type-bar-button {

    background-color: #f8f9fa; 
    color: #000; 
    border: 1px solid #ddd;
    border-radius: 8px; /* Скругленные углы для кнопок */
    text-align: left;
    padding: 10px;
    box-shadow: none; 
    transition: background-color 0.3s, border-color 0.3s;
    outline: none;
}

.type-bar-button:focus, 
.type-bar-button:active {
    outline: none !important; 
    box-shadow: none !important;
    border-color: #ddd !important;
    background-color: #f8f9fa !important;
}

.type-bar-button:hover {
    background-color: #e2e6ea; 
}

.type-bar-list {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 8px; /* Скругленные углы для списка */
    max-height: 300px; 
    overflow-y: auto; 
    display: flex; /* Делает список горизонтальным */
    overflow-x: auto; /* Добавляет горизонтальную прокрутку */
    justify-content: space-evenly;
}

.type-bar-list .list-group-item {
    border: none; 
    padding: 10px; 
    background-color: #fff; 
    white-space: nowrap; /* Предотвращает перенос элементов */
    border-radius: 8px; /* Скругленные углы для элементов списка */
}

.type-bar-list .list-group-item.active {
    background-color: #e2e6ea; 
    color: #000; 
}

.type-bar-list .list-group-item:hover {
    background-color: #f8f9fa; 
}

@media (max-width: 768px) {
    .type-bar-button {
        font-size: 14px;
        padding: 8px;
    }

    .type-bar-list {
        font-size: 14px;
    }
}

/* Shop.css */

.carousel-wrapper {
    margin: 0;
    padding: 0;
    margin-top: -10px;
    margin-right: -1px;
}

.carousel-item img {
    width: 100%;
    height: auto;
}

.alert {
    background-color: #0000002a;
    margin-top: 40px;
    width: 100%;
    max-height: 100px;
}

.alert h3 {
    font-weight: 400;
    color: #000000d2;
    text-align: center;
}

.shop-content {
    display: flex;
    align-items: flex-start; /* Выравнивание по верхнему краю */
    flex-direction: column; /* Делаем вертикальное направление */
}

.type-bar-wrapper {
    width: 100%;
    margin-bottom: 10px; /* Добавляем отступ снизу */
}

.product-list-wrapper {
    width: 100%;
    margin-top: 0; /* Убираем верхний отступ для ProductList */
}
