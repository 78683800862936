
body {
    font-family: 'Nunito', sans-serif;
}











/* Обертка для карусели */
.carousel-wrapper {
    margin: 0;
    padding: 0;
    margin-top: -10px;
    margin-right: -1px;
}

.carousel-item img {
    width: 100%;
    height: auto;
}

/* Стили для блока объявлений */
.alert {
    background-color: #0000002a;
    margin-top: 26px;
    width: 100%;
    max-height: 100px;
}

.alert h3 {
    font-weight: 400;
    color: #000000d2;
    text-align: center;
}

/* Контейнер для содержимого магазина */
.shop-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Выравнивание по верхнему краю */
}

/* Обертка для TypeBar */
.type-bar-wrapper {
    width: 100%;
    margin-bottom: 10px; /* Добавляем отступ снизу */
}

/* Обертка для списка продуктов */
.product-list-wrapper {
    width: 100%;
    margin-top: 0; /* Убираем верхний отступ для ProductList */












    position: relative;
}

/* Контейнер для списка продуктов и страниц */
.product-pages-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Центрируем содержимое по горизонтали */
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 992px) {
    .shop-content {
        align-items: flex-end; /* Выравнивание по правому краю */
    }
}

















.shop-container {
    position: relative;
}

.filter-toggle-button {
    margin-bottom: 10px;
}

.filter-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px; /* Ширина фильтра */
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Обеспечивает, чтобы фильтр был поверх других элементов */
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.filter-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-height: 90%; /* Чтобы фильтр не перекрывал всю высоту экрана */
    overflow-y: auto;
}

.products-wrapper {
    margin-right: 0; /* Убираем отступ, чтобы продукты занимали всё пространство */
}

.category-container {
    padding: 10px;
}
