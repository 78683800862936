body {
    font-family: 'Nunito', sans-serif;
}





.productlist{
    display: flex;
    padding: 0;
}
@media (max-width: 992px) {
    .productlist{
        display: flex;
        justify-content: center;
    }

} 


